<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">

      <!-- Navbar -->
      <div class="col-12 mb-3">
        <div class="card no-bottom-margin">
          <div class="section-sub-menu">
            <div class="section-sub-menu">
              <ul class="nav line-tabs borderless flex-center">
                <li v-for="agent in agentList" :key="agent.code" class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: tab.active === agent.code }"
                     v-on:click="selectTab(agent.code)" data-toggle="tab">{{ agent.label }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card mb-3">
          <div class="card-body">
            <h5>Atenção! Muito cuidado ao alterar as configurações do importador de tarifários. Um parâmetro incorreto pode causar sérias inconsistências à base de dados do sistema!</h5>
          </div>
        </div>

        <div v-for="(rule,index) in rules" :key="index" v-show="index === tab.active" class="card mb-3">
          <div class="card-body">
            <form data-vv-scope="spreadsheet">
              <div class="row">
                <div class="col-12 pb-2 mb-2">
                  <h6>{{ panelDescription(index) }}</h6>
                </div>
              </div>
              <div v-for="(properties, key) in rule" :key="key" class="row">
                <div class="col-12 p-0 m-0">
                  <div class="row">
                    <div class="col-sm-6 col-7">
                      <h6 class="grey-label">{{ key }}
                        <span><i class="ft-alert-circle"></i><md-tooltip md-direction="right">{{ properties.ruleKeyDescription }}</md-tooltip></span>
                      </h6>
                    </div>
                    <div class="col-sm-6 col-5 p-0 m-0">
                      <button
                        v-show="!showProcessingMessage"
                        type="button"
                        class="btn btn-xs responsive-width pull-right p-1"
                        @click="deleteProperty(properties)"
                        :disabled="sendDisabled || !isSuperAdmin">
                        {{ deletedPropertyLabel(properties) }}
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <!-- Tipo de dado -->
                    <div class="col-lg-4 col-md-5 col-sm-6">
                      <label for="ruleType" class="m-0 p-0"><small>Tipo de propriedade</small></label>
                      <custom-input
                        v-model="properties.ruleType.label"
                        name="properties.ruleType.label"
                        type="text"
                        rootClassName="md-form my-0 py-0"
                        inputClassName="md-form-control"
                        :disabled="true"
                        :error="submitted ? errors.first('properties.ruleType.label') : ''">
                      </custom-input>
                    </div>

                    <!-- Inteiro -->
                    <div class="col-sm-2" v-if="properties.ruleType.code === 1">
                      <label class="small grey-label pb-0 mb-0">Valor</label>
                      <custom-input
                        v-model="properties.intValue"
                        name="properties.intValue"
                        type="number"
                        rootClassName="md-form my-0 py-0"
                        inputClassName="md-form-control"
                        :disabled="properties.blockedConfig && !isSuperAdmin"
                        :error="submitted ? errors.first('properties.intValue') : ''">
                      </custom-input>
                    </div>

                    <!-- Texto simples -->
                    <div class="col-sm-4" v-if="properties.ruleType.code === 2">
                      <label class="small grey-label pb-0 mb-0">Valor</label>
                      <custom-input
                        v-model="properties.stringValue"
                        name="properties.stringValue"
                        type="text"
                        rootClassName="md-form my-0 py-0"
                        inputClassName="md-form-control"
                        :disabled="properties.blockedConfig && !isSuperAdmin"
                        :error="submitted ? errors.first('properties.stringValue') : ''">
                      </custom-input>
                    </div>

                    <!-- Array de inteiros -->
                    <div class="col-sm-2" v-if="properties.ruleType.code === 3">
                      <label class="small grey-label pb-0 mb-0">Valor</label>
                      <custom-input v-for="(value, index) in properties.arrayValue" :key="index"
                        v-model="properties.arrayValue[index]"
                        name="properties.arrayValue[index]"
                        type="number"
                        rootClassName="md-form my-0 py-0 mx-1"
                        inputClassName="md-form-control"
                        :disabled="properties.blockedConfig && !isSuperAdmin"
                        :error="submitted ? errors.first('properties.arrayValue[index]') : ''">
                      </custom-input>
                    </div>

                    <!-- Array de strings -->
                    <div class="col-md-7" v-if="properties.ruleType.code === 4">
                      <label class="small grey-label pb-0 mb-0">Valores</label>
                      <div class="row" v-for="(value, index) in properties.arrayValue" :key="index">
                        <div class="col-8">
                          <custom-input
                            v-model="properties.arrayValue[index]"
                            name="properties.arrayValue[index]"
                            type="text"
                            rootClassName="md-form my-0 py-0 mx-1"
                            inputClassName="md-form-control"
                            :disabled="properties.blockedConfig && !isSuperAdmin"
                            :error="submitted ? errors.first('properties.arrayValue[index]') : ''">
                          </custom-input>
                        </div>
                        <div class="col-2 ">
                          <button
                            v-if="index>0"
                            type="button"
                            class="btn btn-xs responsive-width m-1 p-1"
                            @click="moveUpInList(properties, index)"
                            :disabled="sendDisabled">
                            ▴
                          </button>
                          <button
                            v-if="index<properties.arrayValue.length-1"
                            type="button"
                            class="btn btn-xs responsive-width m-1 p-1"
                            @click="moveDownInList(properties, index)"
                            :disabled="sendDisabled">
                            ▾
                          </button>
                        </div>
                        <div class="col-2">
                          <button
                            type="button"
                            class="btn btn-xs responsive-width m-1 p-1"
                            @click="deleteItem(properties, index)"
                            :disabled="sendDisabled">
                            {{ deletedLabel(properties.arrayValue[index]) }}
                          </button>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-3 mx-0 px-0">
                          <button
                            type="button"
                            class="btn btn-outline-primary btn-fill btn-sm btn mt-2 p-1"
                            @click="onAddValue(properties.arrayValue, properties.ruleType.code)">
                            <small>Adicionar</small>
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Array multidimensional -->
                    <div class="col-md-7" v-if="properties.ruleType.code === 5">
                      <div class="row">
                        <div class="col-4 pl-0 ml-0">
                          <label class="small grey-label pb-0 mb-0">Substituir</label>
                        </div>
                        <div class="col-4 pl-0 ml-0">
                          <label class="small grey-label pb-0 mb-0">Substituto</label>
                        </div>
                      </div>

                      <div class="row" v-for="(register, index) in properties.arrayValue" :key="index">
                        <div class="col-4 pl-0 ml-0">
                          <custom-input
                            v-model="properties.arrayValue[index].replace"
                            name="properties.arrayValue[index].replace"
                            type="text"
                            rootClassName="md-form my-0 py-0 mx-1"
                            inputClassName="md-form-control"
                            :disabled="properties.blockedConfig && !isSuperAdmin"
                            :error="submitted ? errors.first('properties.arrayValue[index].replace') : ''">
                          </custom-input>
                        </div>

                        <div class="col-4 pl-0 ml-0">
                          <custom-input
                            v-model="properties.arrayValue[index].replacer"
                            name="properties.arrayValue[index].replacer"
                            type="text"
                            rootClassName="md-form my-0 py-0 mx-1"
                            inputClassName="md-form-control"
                            :disabled="properties.blockedConfig && !isSuperAdmin"
                            :error="submitted ? errors.first('properties.arrayValue[index].replacer') : ''">
                          </custom-input>
                        </div>
                        <div class="col-2">
                          <button
                            v-if="index>0"
                            type="button"
                            class="btn btn-xs responsive-width m-1 p-1"
                            @click="moveUpInList(properties, index)"
                            :disabled="sendDisabled">
                            ▴
                          </button>
                          <button
                            v-if="index<properties.arrayValue.length-1"
                            type="button"
                            class="btn btn-xs responsive-width m-1 p-1"
                            @click="moveDownInList(properties, index)"
                            :disabled="sendDisabled">
                            ▾
                          </button>
                        </div>
                        <div class="col-2">
                          <button
                            type="button"
                            class="btn btn-xs responsive-width m-1 p-1"
                            @click="deleteItem(properties, index)"
                            :disabled="properties.blockedConfig && !isSuperAdmin">
                            {{ deletedLabel(properties.arrayValue[index]) }}
                          </button>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-3 mx-0 px-0 mt-0 pt-0">
                          <button
                            type="button"
                            class="btn btn-outline-primary btn-fill btn-sm btn mt-2 p-1"
                            @click="onAddValue(properties.arrayValue, properties.ruleType.code)">
                            <small>Adicionar</small>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr/>
                </div>
              </div>

              <!-- Nova propriedade -->
              <div class="row">
                <div class="col-lg-5 col-md-6 pl-0">
                  <label for="property" class="m-0 p-0"> <small>Nova propriedade</small></label>
                  <v-select class="grey-field" name="property" label="name" :clearable="false" :searchable="false"
                    v-model="newProperty" :options="ruleKeysList">
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('property')" class="royalc-error-field">{{ errors.first('property') }}</small>
                  </transition>
                </div>

                <div class="col-lg-3 mx-0 px-0 pl-0 ml-0">
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-fill btn-sm btn mt-4"
                    @click="onAddRule(index)"
                    :disabled="!isSelected">
                    Adicionar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!--  Actions Buttons  -->
      <div class="col-12 pr-4 pb-3 mr-4 mb-3">
        <button
          type="button"
          class="btn btn-sm btn-primary btn-fill pull-right mr-2"
          @click="onSave"
          :disabled="sendDisabled">
          Salvar tudo
        </button>
      </div><!--  Actions Buttons  -->

    </div>
  </div>
</template>

<script>
import TariffService from '@/services/TariffService'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'

// Select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Components
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'tariffImportConfig',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.tariffImport') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: false,
      disabled: false,
      agentList: [],
      agent: {
        code: 'excel-eternity',
        label: 'Eternity'
      },
      showProcessingMessage: false,
      idiom: [],
      type: [],
      rules: [],
      ruleKeysList: [],
      ruleTypeList: [],
      newProperty: null,
      tab: {
        active: null
      },
      unsuedKeys: [],
      submitted: false
    }
  },
  components: {
    Loading,
    /* eslint-disable-next-line */
    TariffService,
    CustomInput,
    vSelect
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
    this.getAgentOptions()
    this.getRuleKeysOptions()
    this.getRuleTypesOptions()
    this.getRuleData()
  },
  methods: {
    getAgentOptions () {
      let _this = this
      _this.showProcessingMessage = true
      _this.disabled = true
      TariffService.getAgentOptions().then(res => {
        _this.agentList = Object.entries(res.data.data).map((key) => {
          return {
            code: key[0],
            label: key[1]['shortDescription'],
            description: key[1]['longDescription'],
            ruleKeysList: []
          }
        })
        _this.tab.active = _this.agentList[0].code
        _this.showProcessingMessage = false
        _this.disabled = false
      }).catch(() => {
        _this.showProcessingMessage = false
        _this.disabled = false
      })
    },
    getRuleData () {
      let _this = this
      _this.showProcessingMessage = true
      _this.disabled = true
      TariffService.getRuleData().then(res => {
        _this.rules = res.data.data
        _this.showProcessingMessage = false
        _this.disabled = false
      }).catch(() => {
        _this.showProcessingMessage = false
        _this.disabled = false
      })
    },
    getRuleKeysOptions () {
      let _this = this
      _this.showProcessingMessage = true
      _this.disabled = true
      TariffService.getRuleKeysOptions().then(res => {
        _this.ruleKeysList = res.data.data
        _this.showProcessingMessage = false
        _this.disabled = false
      }).catch(() => {
        _this.showProcessingMessage = false
        _this.disabled = false
      })
    },
    getRuleTypesOptions () {
      let _this = this
      _this.showProcessingMessage = true
      _this.disabled = true
      TariffService.getRuleTypeOptions().then(res => {
        _this.ruleTypeList = res.data.data
        _this.showProcessingMessage = false
        _this.disabled = false
      }).catch(() => {
        _this.showProcessingMessage = false
        _this.disabled = false
      })
    },
    onSave () {
      var _this = this
      _this.submitted = true
      _this.disabled = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true
          TariffService.saveRules(_this.rules).then(res => {
            _this.getRuleData()
            _this.disabled = false
            _this.isLoading = false
          }).catch(err => {
            if (err.code === 400) {
              _this.displayErrors(err.errors)
            }
            _this.isLoading = false
          })
        }
      })
    },
    selectTab (code) {
      this.tab.active = code
    },
    onAddRule (tab) {
      let agent = this.rules[tab]
      let newProperty = {
        id: null,
        intValue: null,
        stringValue: null,
        arrayValue: [],
        initialValue: null,
        replaceValue: null,
        ruleType: {
          code: this.newProperty.type,
          label: this.newProperty.description
        },
        altered: false,
        deleted: false
      }

      newProperty.ruleType.label = this.ruleTypeList.find(x => x.code === this.newProperty.type).label

      if (this.newProperty.type === 1) {
        newProperty.intValue = 0
      }

      if (this.newProperty.type === 2) {
        newProperty.stringValue = ''
      }

      if (this.newProperty.type >= 3 && this.newProperty.type <= 5) {
        newProperty.arrayValue = []
        this.onAddValue(newProperty.arrayValue, this.newProperty.type)
      }

      agent[this.newProperty.name] = newProperty
      this.$forceUpdate()
    },
    onAddValue (arrayValue, ruleTypeCode) {
      let item = null
      if (ruleTypeCode === 5) {
        item = {
          replace: '',
          replacer: ''
        }
      } else {
        item = ''
      }
      arrayValue.push(item)
    },
    customInputLabel (label, description) {
      return label + this.createSpan(description)
    },
    createSpan (description) {
      return <span><i class="ft-alert-circle"></i><md-tooltip md-direction="right">${description}</md-tooltip></span>
    },
    deletedLabel (item) {
      return item.deleted ? 'Recuperar valor' : 'Excluir valor'
    },
    deletedPropertyLabel (property) {
      return property.deleted ? 'Recuperar propriedade' : 'Excluir propriedade'
    },
    moveUpInList (list, index) {
      let element = list.arrayValue[index]
      list.arrayValue.splice(index, 1)
      let newInitialList = list.arrayValue.slice(0, index - 1)
      let newFinalList = list.arrayValue.slice(index - 1)
      list.arrayValue = [...newInitialList, element, ...newFinalList]
      this.$forceUpdate()
    },
    moveDownInList (list, index) {
      let element = list.arrayValue[index]
      list.arrayValue.splice(index, 1)
      let newInitialList = list.arrayValue.slice(0, index + 1)
      let newFinalList = list.arrayValue.slice(index + 1)
      list.arrayValue = [...newInitialList, element, ...newFinalList]
      this.$forceUpdate()
    },
    deleteItem (list, index) {
      list.arrayValue.splice(index, 1)
    },
    deleteProperty (property) {
      property.deleted = !property.deleted
    },
    panelDescription (shortDescription) {
      if (this.agentList.lenght === 0) {
        return ''
      }
      let agent = this.agentList.find(x => x.code === shortDescription)
      if (agent === undefined) {
        return shortDescription
      }
      // return this.agentList.find(x => x.code === shortDescription).description
      return agent.description
    }
  },
  computed: {
    sendDisabled () {
      return this.disabled
    },
    isSelected () {
      return this.newProperty !== null
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
.swal2-popup {
  width: inherit !important;
}

.card-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.md-form-control {
  height: 30px;
}

.grey-field{
  background: #fff;
  border: none;
  color: gray;
  /* font-variant: small-caps; */
}

.grey-field .selected{
  color: gray;
  text-transform: capitalize;
}

.btn-outline-primary{
  border-color: #918158;
  color: #918158;
}

.btn-outline-primary:hover{
  background-color: #918158;
  color: white;
}

.responsive-width {
    font-size: smaller;
}
</style>
